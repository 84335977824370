import moment from "moment";
import "moment/locale/nl";
import { RRule } from "rrule";
import config from "@/config";

export function countItemsByResourceId(data, resourceId) {
  return data.filter((item) => item.resourceId === resourceId).length;
}

export function groupParentsChildren(data) {
  var helper = {};
  var rootGroups = data
    .map((p) => ({
      type: p.type,
      title: p.group,
      //a hack, but it moves the blocked pitches down
      sort: p.group == "Geblokkeerd" ? 1 : 0,
      children: [],
      eventAllow: () => false
    }))
    .reduce(function (r, o) {
      var key = `${o.type}-${o.title}`;
      if (!helper[key]) {
        helper[key] = Object.assign({}, o); // create a copy of o
        r.push(helper[key]);
      } else {
        helper[key].id = key;
        if (helper[key].children.length <= 1) {
          //Only do this once
          var c = data
            .filter((item) => key.endsWith(item.group))
            .map((pl) => ({
              title: pl.pitchNumber,
              id: pl.id,
              sort: pl.pitchNumber
            }));
          helper[key].children = c;
          helper[key].title += ` (${c.length})`;
        }
      }
      return r;
    }, []);

  return rootGroups;
}

export function calculateTotals(campingPitchCapacity, bookingEvents) {
  //Generate all the dates for each event
  var dates = bookingEvents.map((e) =>
    new RRule({
      freq: RRule.DAILY,
      interval: 1,
      dtstart: e.start,
      until: moment(e.end).subtract({ day: 1 }).toDate()
    })
      .all()
      .map((d) => ({ date: d, resourceId: e.resourceId, used: 1 }))
  );
  //Put those into a singledimensional array
  var arr = [].concat.apply([], dates);
  //Reduce that into objects with date and counts
  var helper = {};
  var result = arr.reduce((r, o) => {
    var key = `${o.date.toDateString()}`;

    if (!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    } else {
      helper[key].used++;
    }

    return r;
  }, []);

  //Add those counts as background events in the total row
  return result.map((r) => {
    var available = campingPitchCapacity - r.used;
    var availableClass = "availability-success";
    if (available <= config.availabilityTreshold.warning) availableClass = "availability-warning";
    if (available <= config.availabilityTreshold.danger) availableClass = "availability-danger";

    //var usedPct = Math.trunc((r.used / campingPitchCapacity) * 100);
    return {
      allDay: true,
      overlap: false,
      resourceId: -1,
      start: r.date,
      title: `${available}`,
      display: "background",
      //classNames: `text-center occupied-${Math.trunc((usedPct / 95) * 9 + usedPct / 100)}0`
      classNames: `${availableClass}`
    };
  });
}
